<template>
  <service-menu current="inspection"/>
  <div class="service-top">
    <div class="content">
      <h1>検品サービス</h1>
      <p>
        初めての海外商品の輸入に不安ですか？<br>
        日本人スタッフがお届け前に対応するので安心です。
      </p>
    </div>
  </div>
  <div class="pain">
    <p class="title">海外の商品で、こんなお困り事はありませんか？</p>
    <div class="items">
      <div class="item">
        中国語の商品タグ<br>がついている
      </div>
      <div class="item">
        不良品が多い<br>悪評にならないか<br>不安
      </div>
      <div class="item">
        パッケージが<br>破損していた。<br>不信感が募る
      </div>
    </div>
    <div class="items-sp">
      <img src="@/assets/confused.svg"/>
      <ul>
        <li>”中国語の商品タグがついている”</li>
        <li>”不良品が多い・悪評になる不安”</li>
        <li>”パッケージが破損していた。”</li>
        <li>”サイズや数量が合わない”</li>
      </ul>
    </div>
    <div class="solution">
      <p>日本基準の検品！</p>
      <p>SellerPortで全て解決</p>
    </div>
  </div>
  <div class="plan">
    <h2>料金プラン</h2>
    <table class="pc" cellspacing="0">
      <tr>
        <td colspan="2" class="transparent"></td>
        <td width="25%">VIP会員</td>
        <td width="25%">通常会員</td>
      </tr>
      <tr>
        <td rowspan="5" class="category">アパレル</td>
        <td>
          基本検品
          <div class="small-text">（サイズ、色、数量、汚れ、不良品確認など）</div>
        </td>
        <td>4元/件</td>
        <td>5元/件</td>
      </tr>
      <tr>
        <td>
          布タグ外し
        </td>
        <td>2元/件</td>
        <td>3元/件</td>
      </tr>
      <tr>
        <td>
          洗濯タグ外し
        </td>
        <td>2元/件</td>
        <td>3元/件</td>
      </tr>
      <tr>
        <td>
          紙タグ外し
        </td>
        <td>0.5元/件</td>
        <td>1元/件</td>
      </tr>
      <tr>
        <td>
          OPP袋詰め替え
        </td>
        <td>0.5元/件</td>
        <td>1元/件</td>
      </tr>
      <tr>
        <td rowspan="2" class="category">雑貨</td>
        <td>基本検品</td>
        <td>2元/件</td>
        <td>3元/件</td>
      </tr>
      <tr>
        <td>緩衝材梱包</td>
        <td>1元/件〜</td>
        <td>2元/件〜</td>
      </tr>
    </table>
    <div class="sp">
      <div class="title">
        アパレル
      </div>
      <div v-for="item in planApparel.items" :key="item.title" class="service-block">
        <div class="subtitle">
          <h3>{{item.title}}</h3>
          <div class="small-text" v-if="item.subtitle">{{item.subtitle}}</div>
        </div>
        <table>
          <tr>
            <td>VIP会員</td><td>通常会員</td>
          </tr>
          <tr>
            <td>
              <span>{{item.vipPrice}}元</span>/件
            </td>
            <td>
              <span>{{item.normalPrice}}元</span>/件
            </td>
          </tr>
        </table>
      </div>
      <div class="title">
        雑貨
      </div>
      <div v-for="item in planGoods.items" :key="item.title" class="service-block">
        <div class="subtitle">
          <h3>{{item.title}}</h3>
          <div v-if="item.subtitle">{{item.subtitle}}</div>
        </div>
        <table>
          <tr>
            <td>VIP会員</td><td>通常会員</td>
          </tr>
          <tr>
            <td>
              <span>{{item.vipPrice}}元</span>/{{item.unit}}
            </td>
            <td>
              <span>{{item.normalPrice}}元</span>/{{item.unit}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <div class="service-flows">
    <h2>検品の流れ</h2>
    <div>
      <dl>
        <dh>Step1.</dh>
        <dd class="pc title">商品画像と実物の色を確認</dd>
        <dd><img src="@/assets/inspection1.png"/></dd>
        <dd class="sp">商品画像と実物の色を確認</dd>
      </dl>
      <dl>
        <dh>Step2.</dh>
        <dd class="pc title">サイズ表示と実物のサイズを確認</dd>
        <dd><img src="@/assets/inspection2.png"/></dd>
        <dd class="sp">サイズ表示と実物のサイズを確認</dd>
      </dl>
      <dl>
        <dh>Step3.</dh>
        <dd class="pc title">入荷数が予定通りか確認</dd>
        <dd><img src="@/assets/inspection3.png"/></dd>
        <dd class="sp">入荷数が予定通りか確認</dd>
      </dl>
      <dl>
        <dh>Step4.</dh>
        <dd class="pc title">検品</dd>
        <dd><img src="@/assets/inspection4.png"/></dd>
        <dd class="sp">検品</dd>
      </dl>
      <dl>
        <dh>Step5.</dh>
        <dd class="pc title">畳み直し</dd>
        <dd><img src="@/assets/inspection5.png"/></dd>
        <dd class="sp">畳み直し</dd>
      </dl>
      <dl>
        <dh>Step6.</dh>
        <dd class="pc title">袋入れ</dd>
        <dd><img src="@/assets/inspection6.png"/></dd>
        <dd class="sp">袋入れ</dd>
      </dl>
    </div>
  </div>
  <div class="customer-voice">
    <h1>お客様の声</h1>
    <div class="customer-voice-row">
      <customer-voice
        align="left"
        title="とても丁寧で、見違えました"
        description="<br>中国語の商品タグを取り替えるにもしっかり縫い付けられているだろうし、少し困っていました。<br>
SellerPortに商品タグ外しもお願いしたら綺麗に外していただけてよかったです。<br>
改めて自分達で直す必要がないので、すぐに販売できるところもありがたいなと思いました。"
        profile="user3.png"
        />
      <customer-voice
        align="left"
        title="汚れなどしっかり見てくれました"
        description="<br>SellerPortの検品オプションをつけたら、汚れがついている商品や数量や色が間違っている商品は事前に弾いてくれるので、とても便利でした。<br>
実際に商品が手元に届くまではどんな商品が届くのかと海外の商品を輸入することが不安だったので、これなら受け取る前に確認もできるし安心して続けられそうです。"
        profile="user4.png"
        />
    </div>
  </div>
  <div class="faqs">
    <h1>よくあるご質問</h1>
    <faq
      v-for="(faq, index) in faqs"
      :key="index"
      :question="faq.question"
      :answer="faq.answer"
      />
  </div>
</template>

<script>
import Faq from '@/components/atoms/Faq.vue'
import CustomerVoice from '../components/molecules/CustomerVoice.vue'
import ServiceMenu from '../components/molecules/ServiceMenu.vue'

export default {
  name: 'Service',
  components: {
    Faq, CustomerVoice, ServiceMenu,
  },
  data() {
    return {
      planApparel: {
        items: [
          {title: '基本検品', subtitle: '（サイズ、色、数量、汚れ、不良品確認など）', vipPrice: 4, normalPrice: 5},
          {title: '布タグ外し', vipPrice: 2, normalPrice: 3},
          {title: '洗濯タグ外し', vipPrice: 2, normalPrice: 3},
          {title: '紙タグ外し', vipPrice: 0.5, normalPrice: 1},
          {title: 'OPP袋詰め替え', vipPrice: 0.5, normalPrice: 1},
        ]
      },
      planGoods: {
        items: [
          {title: '基本検品', subtitle: '（サイズ、色、数量、汚れ、不良品確認など）', vipPrice: 4, normalPrice: 5, unit: '件'},
          {title: '緩衝材梱包', vipPrice: 1, normalPrice: 2, unit: '件〜'},
        ]
      },
      faqs: [
        {
          question: '返品や交換は可能ですか？',
          answer: '返品や交換は、すぐに対応できればセラーに交渉することはできますが、国際発送の場合は日数が経過しているので基本的には返品や交換が難しいです。 返品の場合は、国内送料が別途かかる場合もございます。',
        },
        {
          question: '電気系の検品ができますか？',
          answer: '複雑ではなく、電池を入れて確認や電源に挿して確認は可能でございます。別途機械等が必要な場合は、原則会員様にてご用意していただく必要がございます。'
        },
        {
          question: '指定のOPP袋に詰め替えできますか？',
          answer: '可能です。OPP袋の受注生産することも可能です(最低数量制限あり)。'
        },
      ]
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.service-top {
  background: url('../assets/inspection-top.png');
  height: 350px;
  .content {
    background: rgba(51,51,51,0.78);
    height: 100%;
    padding-left: 8%;
    color: white;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    h1 {
      border-bottom: 1px solid white;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
    p {
      margin-bottom: 50px;
    }
  }
}
.pain {
  p {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
  }
  .items {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    .item {
      background: #F7F7F7;
      width: 200px;
      height: 200px;
      border-radius: 200px;
      word-break: break-all;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin: 0 20px;
    }
  }
  .solution {
    background: #F2B504;
    color: white;
    text-align: center;
    padding: 50px 0px 20px 0px;
    font-size: 22px;
    font-weight: bold;
    p {
      line-height: 130%;
      margin: 0px;
    }
    &::after {
      content: "";
      border-top: 30px solid transparent;
      border-right: 30px solid transparent;
      border-left: 30px solid transparent;
      border-bottom: 30px solid #F2B504;
      position: relative;
      top: -170px;
    }
  }
}
.plan {
  background: #F7F7F7;
  padding-bottom: 80px;
  h2 {
    margin: 0;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .small-text {
    font-size: 12px;
  }
}
.service-flows {
  padding: 40px 0 80px 0;
  h2 {
    margin: 0;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
  }
  dl {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 80px;
    }
    dh {
      text-align: right;
      padding-right: 20px;
      color: #F2B504;
      font-size: 20px;
      font-weight: bold;
    }
    dd:nth-child(1), .title {
      width: 35%;
      min-width: 300px;
      font-size: 20px;
      font-weight: bold;
    }
    dd:last-child {
      text-align: right;
    }
  }
}
.customer-voice {
  background: #F7F7F7;
  padding-top: 50px;
  padding-bottom: 100px;
  h1 {
    text-align: center;
    margin-bottom: 50px;
  }
  .customer-voice-row {
    display: flex;
    justify-content: space-around;
  }
}
.faqs {
  h1 {
    text-align: center;
  }
}

@media (min-width: 751px) {
  .plan {
    table {
      width: 70%;
      margin: auto;
      padding: 3px;
      td {
        border: solid 5px #F7F7F7;
        text-align: center;
        padding: 10px;
        background: white;
      }
      .category {
        background: #606060;
        color: white;
        text-align: center;
        font-weight: bold;
      }
      .transparent {
        background: transparent;
      }
    }
  }
  .pain {
    .items-sp {
      display: none;
    }
  }
}

@media screen and (max-width: 750px) {
  .service-top {
    background: url('../assets/inspection-top-sp.png');
    background-size: cover;
  }
  .customer-voice {
    .customer-voice-row {
      flex-direction: column;
      .voice:not(:nth-child(1)) {
        margin-top: 30px;
      }
    }
  }
  .service-flows {
    dl {
      flex-direction: column;
      align-items: center;
      dh {
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 30px;
        font-size: 23px;
        padding-left: 15px;
      }
      dd {
        margin-inline-start: 0;
        img {
          height: 120px;
        }
      }
      dd:last-child {
        font-weight: bold;
        margin-top: 10px;
      }
    }
  }
  .plan {
    .title {
      background-color: #F2B504;
      color: white;
      text-align: center;
      font-weight: bold;
      width: 60%;
      margin: auto;
      border-radius: 5px;
      padding: 10px 0;
      position: relative;
      margin-top: 50px;
      &::before {
        content: "";
        border-top: 30px solid #F2B404;
        border-right: 30px solid transparent;
        border-left: 30px solid transparent;
        position: absolute;
        top: 30px;
      }
    }
    .service-block {
      background-color: white;
      width: 85%;
      padding: 5px 5px 30px 5px;
      margin: 20px auto;
      border-radius: 5px;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
      h3 {
        margin-bottom: 0;
      }
      .subtitle {
        font-weight: bold;
        text-align: center;
        font-size: 15px;
      }
      table {
        text-align: center;
        width: 90%;
        margin-top: 10px;
      }
    }
  }
  .pain {
    .title {
      padding: 20px 8%;
      font-size: 20px;
    }
    .items {
      display: none;
    }
    .items-sp {
      display: block;
      background: #F7F7F7;
      width: 80%;
      margin: auto;
      border-radius: 5px;
      margin-bottom: 30px;
      padding: 50px 10px 10px 10px;
      font-weight: bold;
      position: relative;
      ul {
        padding-inline-start: 0;
        li {
          list-style: none;
          text-align: center;
          padding: 10px;
        }
      }
      img {
        position: absolute;
        top: -15px;
        left: -15px;
      }
    }
  }
}
</style>